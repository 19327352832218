<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="500"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>AMORTIZAR CUENTA POR COBRAR</span>
        </div>
        <div class="pl-3 pt-3 pr-3" style="height: 80vh;" v-if="debt">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-2 pb-2">
                                <span class="subtitle-2">Información de cliente</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-1 pb-1 amortize-debt">
                                <v-container class="pt-1 pb-1">
                                    <v-row>
                                        <v-col cols="12" class="pt-1 pb-1">
                                            <span>Cliente:</span>
                                            <span class="float-right">{{ debt.customer_name }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="pt-1 pb-1">
                                            <span>Deuda Total:</span>
                                            <span class="float-right">{{ debt.total_amount | currency('S/') }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="pt-1 pb-1">
                                            <span>Monto Pendiente:</span>
                                            <span class="float-right total-pending">{{ debt.pending_amount | currency('S/') }}</span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-2 pb-2">
                                <span class="subtitle-2">Pago</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-1 pb-0">
                                <v-container>
                                    <v-row>
                                        <v-col cols="6" class="pt-2 pb-2">
                                            <v-combobox
                                                ref="payment_method"
                                                v-model="form.payment_method"
                                                label="Método de pago"
                                                :items="payment_type"
                                                outlined
                                                :rules="[() => !!form.payment_method || 'Es necesario seleccionar un método de pago']"
                                                hide-details="auto"
                                                required>
                                            </v-combobox>
                                        </v-col>
                                        <v-col cols="6" class="pt-2 pb-2">
                                            <v-text-field
                                                ref="amount"
                                                v-model="form.amount"
                                                label="Monto"
                                                prefix="S/"
                                                type="number"
                                                outlined
                                                :rules="[() => !!form.amount || 'Es necesario ingresar el monto a pagar']"
                                                hide-details="auto"
                                                required
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-2 pb-2">
                                <span class="subtitle-2">Historial</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="amortize-debt history-payments">
                                <v-simple-table dense class="table-kardex">
                                    <thead class="table-kardex-head">
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Hora</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-kardex-body">
                                        <tr v-for="payment in debt.payments" :key="payment.id">
                                            <td>{{ payment.created_at | moment('DD/MM/YYYY') }}</td>
                                            <td>{{ payment.created_at | moment('hh:mm a') }}</td>
                                            <td>
                                                {{ payment.payment_amount | currency('S/') }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-2 pl-3 pt-2 pr-3">
            <template>
                <v-row>
                    <v-col cols="6" class="pt-3 pb-3">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pt-3 pb-3">
                        <v-btn dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Pagar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';

import TicketTools from '../../helpers/TicketTools';

import JQuery from 'jquery';
let $ = JQuery;

export default {
    name: 'Amortize',
    data () {
        return {
            drawer: false,
            payment_type: [
                'CASH',
                'VISA',
                'MASTERCARD',
                'AMERICAN EXPRESS',
                'YAPE'
            ],
            form: {
                debt_id: null,
                amount: null,
                payment_method: null,
                till_id: null
            }
        }
    },
    computed: {
        ...mapState('debts', [
            'debt',
            'response_payment'
        ]),
        ...mapState('tills', [
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('debts', [
            'getDebts',
            'selectDebt',
            'storeAmortize'
        ]),
        async show () {
            await this.clearData();
            console.log(this.selected_till);
            this.form.debt_id = this.debt.id;
            this.form.till_id = this.selected_till.id;
            this.drawer = !this.drawer
        },
        clearData () {
            this.form.debt_id = null;
            this.form.amount = null;
            this.form.payment_method = null;
            this.form.till_id = null;
            this.clearErrors();
        },
        closeForm () {
            this.drawer = false;
        },
        handleCancel () {
            this.clearData();
            this.closeForm();
            this.selectDebt(null);
        },
        async handleStore () {
            if (!this.existErrors()) {
                await this.storeAmortize(this.form);
                this.closeForm();
                this.selectDebt(null);
                if (this.response_payment) {
                    if (this.response_payment.voucher) {
                        await this.handlePrintVoucher(this.response_payment.voucher);
                    }
                }
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        async handlePrintVoucher (sale) {
            if (sale) {
                let content = await TicketTools.printTicketVenta(sale);
                this.printVoucher(content);
            }            
        },
        printVoucher (content) {
            $('<iframe>', {name: 'voucheriframe',class: 'printFrame'})
            .appendTo('body')
            .contents().find('body')
            .append(content);

            window.frames['voucheriframe'].focus();

            setTimeout(() => {                 
                window.frames['voucheriframe'].print();
             }, 500);

            setTimeout(() => { $(".printFrame").remove(); }, 1000);
        }
    }
}
</script>

<style scoped>
.total-pending{
    color: #F44336;
}

.amortize-debt{
    font-size: .9rem !important;
    color: #000000 !important;
}

.history-payments{
    height: 23vh;
    overflow-y: auto;
}
</style>